import { NotificationManager } from 'components/Notification';
import { logErrors } from 'libs/utils/common';
import { handleChangeLanguage } from 'libs/utils/language';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fapiUserService } from 'services/fapi';
import { setupActions } from 'store/slices/setupSlice';
import { userActions } from 'store/slices/userSlice';

// yield put(updateTrackStatus({ trackSection: TRACK_DATA.APPLICATION }));

function* getUserInfoSaga({ payload }) {
  try {
    const { userId } = payload;
    const { data } = yield call(fapiUserService.getUserDetailsById, userId);
    const userInfo = data?.userInfo;
    yield put(userActions.getUserDetailsSucceeded(data));

    handleChangeLanguage(userInfo?.language, userInfo?.countryId);
  } catch (error) {
    yield put(userActions.getUserDetailsFailed());
    logErrors(error);
  }
}

function* requestChangeLanguageSaga({ payload }) {
  const { userId, newLanguage, countryId } = payload;

  try {
    yield fapiUserService.changeUserLanguage(userId, newLanguage);
    yield put(setupActions.fetchSetupData());

    yield put(userActions.requestChangeLanguageSucceeded());
    yield put(
      userActions.changeUserSettingsLanguage({
        language: newLanguage,
        countryId,
      })
    );

    handleChangeLanguage(newLanguage, countryId);
  } catch (error) {
    // Revert i18next language if request change language failed
    const { initialLangCode } = payload;

    NotificationManager.error({
      message: 'notification.error.update',
    });

    handleChangeLanguage(initialLangCode, countryId);

    yield put(userActions.requestChangeLanguageFailed(error.message));
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.getUserDetails.toString(), getUserInfoSaga);
  yield takeLatest(
    userActions.requestChangeLanguage.toString(),
    requestChangeLanguageSaga
  );
}
