import { Form } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import Content from 'components/PublicHeader/Content';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import { REGISTRATION_CUSTOMER_TYPE } from 'libs/constants/registration';
import { RESPONSE_STATUS } from 'libs/constants/statuses';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fapiInvitationService } from 'services/fapi';

import { CustomerNumberInput, VatNumberInput } from '../Common';
import CustomerRequestTypeSelection from '../CustomerTypeSelection';
import InvoiceAddressForm from '../InvoiceAddressForm';
import LegalAgreements from '../LegalAgreements';

const SelfRegistrationForm = (props) => {
  const { userId } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [customerType, setCustomerType] = useState(
    REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER
  );
  const [showNewsLetterCheckbox, setShowNewsLetterCheckbox] = useState(false);
  const isMountedRef = useRef(null);

  const getRegistrationInformationByUserId = useCallback(
    () => fapiInvitationService.getRegistrationInformation(userId),
    [userId]
  );

  const requestNewCustomerAccess = useCallback(
    (value) => fapiInvitationService.requestNewCustomerRegistration(value),
    []
  );

  const requestExistingCustomerAccess = useCallback(
    (value) => fapiInvitationService.requestExistingCustomerRegistration(value),
    []
  );

  const onRequestSuccess = useCallback(() => {
    if (isMountedRef.current) {
      NotificationManager.success({
        message: 'notification.success.requestAccess',
      });

      history.push(linkGenerator('/self-registration-confirmation'));
    }
  }, [history]);

  const onRequestFail = useCallback((error) => {
    if (isMountedRef.current) {
      if (error?.response?.data === RESPONSE_STATUS.invalidZip) {
        NotificationManager.error({
          message: `checkout.${error?.response?.data}`,
        });
      }

      NotificationManager.error({
        message: 'notification.error.requestAccess',
        description: error?.response?.data,
      });
    }
  }, []);

  const {
    execute: executeGetRegistrationInformation,
    status: getRegistrationInformationStatus,
  } = useAsync(getRegistrationInformationByUserId, false);
  const {
    execute: executeNewCustomerRequestAccess,
    status: requestNewCustomerAccessStatus,
  } = useAsync(
    requestNewCustomerAccess,
    false,
    onRequestSuccess,
    onRequestFail
  );
  const {
    execute: executeExistingCustomerRequestAccess,
    status: requestExistingCustomerAccessStatus,
  } = useAsync(
    requestExistingCustomerAccess,
    false,
    onRequestSuccess,
    onRequestFail
  );

  useEffect(() => {
    if (userId) {
      isMountedRef.current = true;
      executeGetRegistrationInformation(userId).then(({ response, error }) => {
        if (isMountedRef.current && response?.status === STATUS_CODE.SUCCESS) {
          const { country, canSubscribeNewsletter } = response?.data;
          form.setFieldsValue({ country });
          setShowNewsLetterCheckbox(!!canSubscribeNewsletter);
        }
        if (error) {
          NotificationManager.error({
            message: 'notification.error.wrongUserIdOrCountryId',
            description: error?.response?.data,
          });
        }
      });
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [form, userId, executeGetRegistrationInformation]);

  const validateMessages = {
    required: (label = '') => {
      return (
        <B2becTranslation
          value="form.validate.required"
          variables={{ label }}
        />
      );
    },
  };

  const onSubscribeNewsletter = useCallback(
    (e) => {
      const isSubscribeNewsLetter = e?.target?.checked;
      form.setFieldsValue({ isSubscribeNewsLetter });
    },
    [form]
  );

  const onNewCustomerSubmit = useCallback(
    (values) => {
      const submitValues = {
        vatNumber: values?.vatNumber,
        name: values?.companyName,
        houseNumber: values?.houseNumber,
        street: values?.street,
        city: values?.city,
        zip: values?.zip,
        country: values?.country,
        phoneNumber: values?.contactNumber,
        eMail: values?.email,
        isSubscribeToNewsletterChecked: !!values?.onSubscribeNewsletter,
        userId,
      };

      isMountedRef.current = true;

      if (userId && submitValues?.country) {
        executeNewCustomerRequestAccess(submitValues);
      } else {
        NotificationManager.error({
          message: 'notification.error.requestAccess',
          description: 'notification.error.wrongUserIdOrCountryId',
        });
      }

      return () => {
        isMountedRef.current = false;
      };
    },
    [userId, executeNewCustomerRequestAccess]
  );

  const onExistingCustomerSubmit = useCallback(
    (values) => {
      if (userId && getRegistrationInformationStatus === ASYNC_STATUS.SUCCESS) {
        const submitValues = {
          customerNumber: values?.sapCustomerNumber,
          userId,
          country: form.getFieldValue('country'),
          isSubscribeToNewsletterChecked: !!values?.isSubscribeNewsLetter,
        };

        isMountedRef.current = true;
        executeExistingCustomerRequestAccess(submitValues);
      }
      return () => {
        isMountedRef.current = false;
      };
    },
    [
      userId,
      form,
      getRegistrationInformationStatus,
      executeExistingCustomerRequestAccess,
    ]
  );

  const renderNewCustomerForm = () => (
    <Form
      className="registration-page__form"
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      scrollToFirstError
      onFinish={onNewCustomerSubmit}
    >
      <VatNumberInput />
      <InvoiceAddressForm customerType={customerType} />
      <LegalAgreements
        showNewsLetterCheckbox={showNewsLetterCheckbox}
        onSubscribeNewsletter={onSubscribeNewsletter}
      />

      <div className="registration-page__form__actions--self-registration">
        <Form.Item noStyle>
          <CustomButton
            className="registration-page__form__actions__submit"
            htmlType="submit"
            disabled={
              getRegistrationInformationStatus !== ASYNC_STATUS.SUCCESS ||
              requestNewCustomerAccessStatus === ASYNC_STATUS.PENDING
            }
          >
            {t('buttonTexts.requestAccess')}
          </CustomButton>
        </Form.Item>
      </div>
    </Form>
  );

  const renderExistingCustomerForm = () => (
    <Form
      className="registration-page__form"
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      scrollToFirstError
      onFinish={onExistingCustomerSubmit}
    >
      <CustomerNumberInput />

      <LegalAgreements
        showNewsLetterCheckbox={showNewsLetterCheckbox}
        onSubscribeNewsletter={onSubscribeNewsletter}
      />

      <div className="registration-page__form__actions--self-registration">
        <Form.Item noStyle>
          <CustomButton
            className="registration-page__form__actions__submit"
            htmlType="submit"
            disabled={
              getRegistrationInformationStatus !== ASYNC_STATUS.SUCCESS ||
              requestExistingCustomerAccessStatus === ASYNC_STATUS.PENDING
            }
          >
            {t('buttonTexts.requestAccess')}
          </CustomButton>
        </Form.Item>
      </div>
    </Form>
  );

  return (
    <Content className="registration-page__form__wrapper">
      <p className="registration-page__form__requirements">
        {t('registration.form.requirements')}
      </p>

      <CustomerRequestTypeSelection onSetCustomerType={setCustomerType} />

      {customerType === REGISTRATION_CUSTOMER_TYPE.NEW_CUSTOMER
        ? renderNewCustomerForm()
        : renderExistingCustomerForm()}
    </Content>
  );
};

SelfRegistrationForm.propTypes = {
  userId: PropTypes.string,
};

SelfRegistrationForm.defaultProps = {
  userId: '',
};

export default SelfRegistrationForm;
