const mergeData = ({ address, keys, regions, ignores, separator }) =>
  keys
    .reduce((result, key) => {
      if (key === 'region') {
        const region = regions[address[key]];
        if (region) {
          return [...result, region.label];
        }
      } else if (address[key] && !ignores.includes(key)) {
        return [...result, address[key]];
      }
      return result;
    }, [])
    .join(separator);

const generateAddressObject = ({
  address,
  regions,
  ignores,
  separator,
  scheme,
}) => {
  const locationKeys =
    scheme === 'USA' ? ['city', 'region', 'zip'] : ['zip', 'region', 'city'];
  const displayTextKeys = [
    'partnerNumber',
    'name',
    'name2',
    'name3',
    'street',
    'houseNumber',
    'street4',
    ...locationKeys,
    'country',
  ];

  return {
    name: mergeData({
      address,
      keys: ['name', 'name2', 'name3'],
      regions,
      ignores,
      separator,
    }),
    address: mergeData({
      address,
      keys: ['street', 'houseNumber'],
      regions,
      ignores,
      separator,
    }),
    street4: mergeData({
      address,
      keys: ['street4'],
      regions,
      ignores,
      separator,
    }),
    location: mergeData({
      address,
      keys: locationKeys,
      regions,
      ignores,
      separator,
    }),
    displayText: mergeData({
      address,
      keys: displayTextKeys,
      regions,
      ignores,
      separator,
    }),
  };
};

export const getAddress = (
  address,
  regions = {},
  ignores = [],
  separator = ' ',
  isUSAAddressScheme = false
) => {
  const scheme = isUSAAddressScheme ? 'USA' : 'default';
  return generateAddressObject({
    address,
    regions,
    ignores,
    separator,
    scheme,
  });
};
