import { useSelector } from 'react-redux';
import { selectCountryNames } from 'store/selectors/configSelector';

function useCountryName(countryName) {
  const countryNames = useSelector(selectCountryNames);

  const foundCountryNameConfig = countryNames.find(
    (country) => country?.value === countryName
  );

  return foundCountryNameConfig ? foundCountryNameConfig.name : countryName;
}

export default useCountryName;
