import { Col, Form, Input, Row } from 'antd';
import clsx from 'clsx';
import { useCheckoutContext } from 'contexts/checkout-context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ShippingNotes.module.scss';

export default function ShippingNotes({ form }) {
  const { t } = useTranslation();
  const { isShippingNotesMandatory } = useCheckoutContext();

  return (
    <Row className="payment-method__wrapper checkout-step1-section__wrapper">
      <Col xl={5} lg={5} md={4} sm={24} xs={24}>
        <h3 className={clsx({ 'mandatory-title': isShippingNotesMandatory })}>
          {t('checkout.step2.shippingNotes')}
        </h3>
      </Col>
      <Col xl={13} lg={13} md={20} sm={24} xs={24}>
        <Form.Item
          className="custom-form-item"
          name="shippingNotes"
          initialValue=""
          rules={[
            {
              message: t(
                'checkout.step3.lowerSection.shippingNotes.isRequired'
              ),
              required: isShippingNotesMandatory,
            },
          ]}
        >
          <Input.TextArea
            className={`custom-form-item ${styles.shippingNotes}`}
            placeholder={t('checkout.step2.shippingNotesPlaceholder')}
            autoSize={{ minRows: 3, maxRows: 10 }}
            allowClear
            onChange={(event) => {
              form.setFieldsValue({
                shippingNotesStep3: event.target.value,
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
