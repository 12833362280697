import './styles.scss';

import { Checkbox } from 'antd';
import B2becTranslation from 'components/B2becTranslation';
import B2BecLink from 'components/B2BLink';
import { CustomFormItem } from 'components/B2bUserForm';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AGREEMENT_CODES = {
  DATA_PRIVACY_STATEMENT: 'dataPrivacyStatement',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
};

const AGREEMENTS = [
  {
    value: AGREEMENT_CODES.DATA_PRIVACY_STATEMENT,
    label: (
      <B2becTranslation
        value="registration.form.legalAgreements.dataPrivacyStatement"
        isComponent
        htmlTags={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          <B2BecLink
            className="registration-page__form__general-terms-and-conditions__link"
            to="/data-protection-policy"
            target="_blank"
          >
            {/* <></> */}
          </B2BecLink>,
        ]}
      />
    ),
  },
  {
    value: AGREEMENT_CODES.TERMS_AND_CONDITIONS,
    label: (
      <B2becTranslation
        value="registration.form.legalAgreements.termsAndConditions"
        isComponent
        htmlTags={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          <B2BecLink
            className="registration-page__form__general-terms-and-conditions__link"
            to="/general-terms-and-conditions"
            target="_blank"
          >
            {/* <></> */}
          </B2BecLink>,
        ]}
      />
    ),
  },
];

function LegalAgreements(props) {
  const { t } = useTranslation();
  const { showNewsLetterCheckbox, onSubscribeNewsletter } = props;
  const [agreementErrors, setAgreementErrors] = useState([]);

  const handleLegalAgreementsChange = (event) => {
    const currentAgreementErrors = [...agreementErrors];
    const { value, checked } = event.target;
    if (checked) {
      const errorIndex = agreementErrors.indexOf(value);
      if (errorIndex > -1) {
        currentAgreementErrors.splice(errorIndex, 1);
      }
    } else {
      currentAgreementErrors.push(value);
    }
    setAgreementErrors(currentAgreementErrors);
  };

  return (
    <>
      <CustomFormItem
        className="registration-page__form__legal-agreements"
        name="legalAgreements"
        displayLabel={false}
        onChange={handleLegalAgreementsChange}
        rules={[
          {
            validateTrigger: 'onSubmit',
            validator: (rule, values) => {
              const errors = [];
              if (Array.isArray(values)) {
                Object.values(AGREEMENT_CODES).forEach((code) => {
                  if (values.findIndex((value) => value === code) === -1) {
                    errors.push(code);
                  }
                });
                if (errors.length > 0) {
                  setAgreementErrors(errors);
                  return Promise.reject();
                }
                setAgreementErrors([]);
                return Promise.resolve();
              }
              Object.values(AGREEMENT_CODES).forEach((code) => {
                errors.push(code);
              });
              setAgreementErrors(errors);
              return Promise.reject();
            },
          },
        ]}
      >
        <Checkbox.Group>
          {AGREEMENTS.map((agreement, index) => {
            const label = t(agreement.value);
            return (
              <div
                className="registration-page__form__agreement"
                key={agreement.value}
              >
                <Checkbox value={agreement.value}>{agreement.label}</Checkbox>
                {agreementErrors?.includes(agreement.value) && (
                  <div className="registration-page__form__general-terms-and-conditions__error">
                    <B2becTranslation
                      value="form.validate.required"
                      variables={{ label }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </Checkbox.Group>
      </CustomFormItem>
      {showNewsLetterCheckbox && (
        <CustomFormItem
          name="isSubscribeNewsLetter"
          displayLabel={false}
          className="registration-page__form__subscribe-newsletter"
        >
          <Checkbox onChange={onSubscribeNewsletter}>
            {t('registration.form.legalAgreements.newsLetter')}
          </Checkbox>
        </CustomFormItem>
      )}
    </>
  );
}

LegalAgreements.propTypes = {
  showNewsLetterCheckbox: PropTypes.bool,
  onSubscribeNewsletter: PropTypes.func,
};

LegalAgreements.defaultProps = {
  showNewsLetterCheckbox: false,
  onSubscribeNewsletter: () => {},
};

export default LegalAgreements;
