import { Col, Form, Input, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import useRegion from 'hooks/useRegion';
import { ADDRESS_SCHEME_TYPES } from 'libs/constants/configs';
import { FIELDS_MAX_LENGTH } from 'libs/constants/formValidateConfig';
import { validateZipCode } from 'libs/utils/validatorUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAddressScheme,
  selectShouldHideHouseNumberEntryField,
} from 'store/selectors/configSelector';

const { TextArea } = Input;

const NewDeliveryAddress = ({ form }) => {
  const { t } = useTranslation();
  const { isShowRegion, regions } = useRegion();

  const shouldHideHouseNumberField = useSelector(
    selectShouldHideHouseNumberEntryField
  );
  const addressScheme = useSelector(selectAddressScheme);
  const isUSAAddressScheme = addressScheme === ADDRESS_SCHEME_TYPES.USA;

  const handleNewAddrPhoneNumberChange = (event) => {
    const { value } = event.target;
    const re = /^\d+$/;
    if (value === '' || re.test(value)) {
      form.setFieldsValue({ newAddrPhoneNumber: value });
    } else {
      form.setFieldsValue({ newAddrPhoneNumber: value.replace(/\D/, '') });
    }
  };

  return (
    <div className="delivery-content">
      <div className="delivery-content__field-content mt-4">
        <Form.Item
          name="newAddrName"
          label={t('form.label.name')}
          className="custom-form-item"
          rules={[
            {
              required: true,
              message: t('checkout.submitErrors.invalidMissingName'),
            },
            {
              type: 'string',
              max: FIELDS_MAX_LENGTH.name,
            },
          ]}
        >
          <Input
            placeholder={t('checkout.typeHere')}
            data-testid="new-address-name"
          />
        </Form.Item>
      </div>
      <div className="delivery-content__field-content mt-4">
        <Form.Item
          name="newAddrName2"
          label={t('form.label.extraText')}
          className="custom-form-item"
          rules={[
            {
              type: 'string',
              max: FIELDS_MAX_LENGTH.name2,
            },
          ]}
        >
          <TextArea
            rows={2}
            style={{ resize: 'none' }}
            placeholder={t('form.placeHolder.extraText')}
          />
        </Form.Item>
      </div>
      <Row gutter={10} lg={{ gutter: 24 }}>
        <Col flex="auto">
          <div className="delivery-content__field-content  mt-4">
            <Form.Item
              name="newAddrStreet"
              label={t('form.label.street')}
              className="custom-form-item"
              rules={[
                {
                  required: true,
                  message: t('checkout.submitErrors.invalidMissingStreet'),
                },
                {
                  type: 'string',
                  max: FIELDS_MAX_LENGTH.street,
                },
              ]}
            >
              <Input
                placeholder={t('checkout.typeHere')}
                data-testid="new-address-street"
              />
            </Form.Item>
          </div>
        </Col>
        {!shouldHideHouseNumberField && (
          <Col span={8}>
            <div className="delivery-content__field-content mt-4">
              <Form.Item
                name="newAddrHouseNumber"
                label={t('form.label.houseNumber')}
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: t(
                      'checkout.submitErrors.invalidMissingHouseNumber'
                    ),
                  },
                  {
                    type: 'string',
                    max: FIELDS_MAX_LENGTH.houseNumber,
                  },
                ]}
              >
                <Input
                  placeholder={t('checkout.typeHere')}
                  data-testid="hourse-number"
                />
              </Form.Item>
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className="delivery-content__field-content mt-4">
            <Form.Item
              name="newAddrStreet4"
              label={t('form.label.street4')}
              className="custom-form-item"
              rules={[
                {
                  type: 'string',
                  max: FIELDS_MAX_LENGTH.street4,
                },
              ]}
            >
              <Input
                placeholder={t('form.placeHolder.street4')}
                data-testid="new-address-street4"
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={12} lg={{ span: 8 }} order={isUSAAddressScheme ? 2 : 0}>
          <div className="delivery-content__field-content mt-4">
            <Form.Item
              name="newAddrZip"
              label={t('form.label.zip')}
              className="custom-form-item"
              rules={[
                {
                  required: true,
                  message: t('checkout.submitErrors.invalidZip'),
                },
                ({ getFieldValue }) => {
                  const country = getFieldValue('newAddrCountry');
                  return validateZipCode(country);
                },
              ]}
            >
              <Input
                placeholder={t('checkout.typeHere')}
                data-testid="new-address-zip"
              />
            </Form.Item>
          </div>
        </Col>
        {isShowRegion && (
          <Col span={24} lg={{ span: 8 }} order={1} className="mt-4">
            <Form.Item
              name="newAddrRegion"
              label={t('form.label.region')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <B2becCustomSelect
                placeholder={t('form.placeHolder.region')}
                options={regions}
              />
            </Form.Item>
          </Col>
        )}
        <Col
          span={24}
          lg={{ span: isShowRegion ? 8 : 16 }}
          order={isUSAAddressScheme ? 0 : 2}
        >
          <div className="delivery-content__field-content mt-4">
            <Form.Item
              name="newAddrCity"
              label={t('form.label.city')}
              className="custom-form-item"
              rules={[
                {
                  required: true,
                  message: t('checkout.submitErrors.invalidMissingCity'),
                },
                {
                  type: 'string',
                  max: FIELDS_MAX_LENGTH.city,
                },
              ]}
            >
              <Input
                placeholder={t('checkout.typeHere')}
                data-testid="new-address-city"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={10} lg={{ gutter: 24 }}>
        {/* Start of new zone */}
        <Col span={12} lg={{ span: 8 }}>
          <div className="delivery-content__field-content mt-4">
            <Form.Item
              name="newAddrPhoneNumber"
              label={t('form.label.contactNumber')}
              className="custom-form-item"
              rules={[
                {
                  required: true,
                  message: t('checkout.submitErrors.invalidTelephone'),
                },
                {
                  type: 'string',
                  max: FIELDS_MAX_LENGTH.Telephone,
                },
              ]}
            >
              <Input
                placeholder={t('checkout.typeHere')}
                data-testid="new-address-phone-number"
                onChange={handleNewAddrPhoneNumberChange}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={12} lg={{ span: 8 }}>
          <div className="delivery-content__field-content mt-4">
            <Form.Item
              name="newAddrEmail"
              label={t('form.label.contactEmail')}
              className="custom-form-item"
              rules={[
                {
                  required: true,
                  message: t('checkout.submitErrors.invalidEmail'),
                },
                {
                  type: 'email',
                  message: t('checkout.submitErrors.invalidEmail'),
                },
                {
                  type: 'string',
                  max: FIELDS_MAX_LENGTH.EMail,
                },
              ]}
            >
              <Input
                placeholder={t('checkout.typeHere')}
                data-testid="new-address-email"
              />
            </Form.Item>
          </div>
        </Col>
        {/* End of new zone */}
      </Row>
      <div className="mt-4">
        <Form.Item
          name="newAddrCountry"
          label={t('form.label.country')}
          className="custom-form-item"
        >
          <Input disabled />
        </Form.Item>
      </div>

      <Form.Item name="newAddrName2" className="ml-4 mt-2" hidden>
        <Input
          disabled
          className="delivery-content__field-input--disabled"
          data-testid="my-address-name-2"
        />
      </Form.Item>
      <Form.Item name="newAddrPartnerNumber" className="ml-4 mt-2" hidden>
        <Input
          disabled
          className="delivery-content__field-input--disabled"
          data-testid="partnerNumber"
        />
      </Form.Item>
    </div>
  );
};

NewDeliveryAddress.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
  }).isRequired,
};

export default React.memo(NewDeliveryAddress);
