import { useCountryName } from 'hooks';
import useRegion from 'hooks/useRegion';
import { isValidArray } from 'libs/utils/array';
import React, { useMemo } from 'react';

import styles from './style.module.scss';

export const BillingAddress = ({ data }) => {
  const { country } = data;
  const countryName = useCountryName(country);
  const { getAddressWithRegion } = useRegion();
  const { address, street4, location } = getAddressWithRegion(data);

  const billingAddrNames = useMemo(() => {
    const names = [data?.name, data?.name2, data?.name3];
    if (isValidArray(names)) {
      return (
        <>
          {names.filter(Boolean).map((name) => (
            <div key={name}>{name}</div>
          ))}
        </>
      );
    }
    return '';
  }, [data.name, data.name2, data.name3]);

  return (
    <div className={styles.deliveryAddress}>
      <strong>{billingAddrNames}</strong>
      <span>{address}</span>
      {street4 && <span>{street4}</span>}
      <span>{location}</span>
      <strong>{countryName || country}</strong>
    </div>
  );
};
