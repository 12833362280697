import './styles.scss';

import { Empty } from 'antd';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const SubCategoryCarousel = ({ images = [], isLoading = false }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton height={500} />;
  }

  if (!isValidArray(images)) {
    return <Empty description={t('noData')} />;
  }

  const { url, type } = images[0];

  return (
    <img
      src={url}
      alt={type}
      width={1260}
      height={456}
      style={{
        width: '100%',
        height: 'auto',
      }}
    />
  );
};

SubCategoryCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
};

SubCategoryCarousel.defaultProps = {
  images: [],
  isLoading: false,
};

export default SubCategoryCarousel;
