import { Col, Form, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import B2becTranslation from 'components/B2becTranslation';
import { useAssignRoles } from 'hooks/useAssignRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAssignableCountries,
  selectAssignableCountryRolesList,
} from 'store/selectors/roleSelector';

import { isValidArray } from '../../libs/utils/array';
import CustomButton from '../CustomButton';
import { DeleteIconBorderless, PlusIcon } from '../Icons';
import styles from './B2becAssignRole.module.scss';

const B2becAssignRole = () => {
  const { findAssignableRoles, firstCountryValue, assignedRoles } =
    useAssignRoles();
  const assignableCountryRolesList = useSelector(
    selectAssignableCountryRolesList
  );
  const assignableCountries = useSelector(selectAssignableCountries);
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Form.Item label={t('form.label.assignRoles')} required>
        <Form.List
          name="countryRoles"
          className="b2b-user-form__assign-roles"
          rules={[
            {
              async validator(_, countryRoles) {
                let roles = {};
                // For technical users
                if (isValidArray(countryRoles)) {
                  const [data] = countryRoles;
                  roles = data?.roles;
                } else {
                  // For normal customers
                  roles = countryRoles?.roles;
                }

                const shouldDisplayRequiredMessage =
                  !isValidArray(assignedRoles) && !isValidArray(roles);

                if (shouldDisplayRequiredMessage) {
                  return Promise.reject(
                    new Error(t('form.error.requiredField'))
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <Col span={24} key={field.key}>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item {...field} name={[field.name, 'salesOrg']}>
                          <B2becCustomSelect options={assignableCountries} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'roles']}
                          rules={[
                            {
                              required: true,
                              message: (
                                <B2becTranslation
                                  value="form.validate.required"
                                  variables={{ label: 'assignRoles' }}
                                />
                              ),
                            },
                          ]}
                        >
                          <B2becCustomSelect
                            options={findAssignableRoles(firstCountryValue)}
                            mode="multiple"
                            optionLabelProp="label"
                            showArrow
                          />
                        </Form.Item>
                      </Col>
                      {index > 0 && (
                        <DeleteIconBorderless
                          onClick={() => {
                            remove(field.name);
                          }}
                          className={styles.minusButton}
                        />
                      )}
                    </Row>
                  </Col>
                );
              })}
              {((isValidArray(assignableCountryRolesList) &&
                fields?.length === 0) ||
                (isValidArray(assignableCountryRolesList, 1) &&
                  fields?.length < assignableCountryRolesList?.length)) && (
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      buttonSize="small"
                      type="ghost"
                      className={styles.addButton}
                      onClick={() => add()}
                    >
                      <PlusIcon /> {t('form.label.addRole')}
                    </CustomButton>
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Form.List>
      </Form.Item>
    </Col>
  );
};
export default B2becAssignRole;
