import './styles.scss';

import { Form } from 'antd';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becSpinner from 'components/B2becSpinner';
import { B2becStepItemLabel, B2becSteps } from 'components/B2becSteps';
import {
  notificationComponent,
  NotificationManager,
} from 'components/Notification';
import { CheckoutProvider } from 'contexts/checkout-context';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ASYNC_STATUS } from 'libs/constants';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { NOTIFICATION_TYPE } from 'libs/constants/notification';
import { getAddress } from 'libs/utils/addressUtils';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectCartData,
  selectFetchCartState,
} from 'store/selectors/cartSelector';
import { getIsLoadingCheckout } from 'store/selectors/checkoutSelector';
import {
  selectDeactivateShoppingCart,
  selectRegions,
} from 'store/selectors/configSelector';
import {
  getCustomerAddress,
  getDeliveryAddresses,
  getIsLoading,
} from 'store/selectors/customerSelector';
import { trackCart } from 'store/slices/adobeAnalyticSlice';
import { ordersActions } from 'store/slices/orderSlice';

import StepOneComponent from './StepOneComponent';
import StepThreeComponent from './StepThreeComponent';
import StepTwoComponent from './StepTwoComponent';

const step1ValidateFields = [
  'newAddrName',
  'newAddrName2',
  'newAddrStreet',
  'newAddrHouseNumber',
  'newAddrCity',
  'newAddrCountry',
  'newAddrZip',
  'newAddrRegion',
  'newAddrPhoneNumber',
  'newAddrEmail',
];

const step2ValidationFields = ['referenceNumber', 'shippingNotes'];
const step3ValidationFields = ['shippingNotesStep3'];

const getFormDeliveryAddr = (value) => {
  if (value.currentDeliveryAddr === 2) {
    return {
      name: value.newAddrName,
      name2: value.newAddrName2,
      street: value.newAddrStreet,
      street4: value.newAddrStreet4,
      houseNumber: value.newAddrHouseNumber,
      city: value.newAddrCity,
      country: value.myAddrCountry,
      region: value.newAddrRegion,
      zip: value.newAddrZip,
      phoneNumber: value.newAddrPhoneNumber,
      email: value.newAddrEmail,
      isNewDeliveryAddress: true,
    };
  }

  return {
    name: value.myAddrName,
    name2: value.myAddrName2,
    street: value.myAddrStreet,
    street4: value.myAddrStreet4,
    houseNumber: value.myAddrHouseNumber,
    city: value.myAddrCity,
    country: value.myAddrCountry,
    region: value.myAddrRegion,
    zip: value.myAddrZip,
    phoneNumber: value.myAddrPhoneNumber,
    email: value.myAddrEmail,
    isNewDeliveryAddress: false,
  };
};

function CheckoutPage(props) {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(1);
  const customerAddress = useSelector(getCustomerAddress);
  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const listCart = useSelector(selectCartData);
  const fetchCartState = useSelector(selectFetchCartState);
  const isCustomerLoading = useSelector(getIsLoading);
  const isCheckoutLoading = useSelector(getIsLoadingCheckout);
  const regions = useSelector(selectRegions);
  const isShoppingCartDeactivated = useSelector(selectDeactivateShoppingCart);

  const { setPageInfoData } = useAdobeAnalysis(TRACK_DATA.CART);

  useEffect(() => {
    if (
      (fetchCartState !== ASYNC_STATUS.IDLE && listCart?.items?.length <= 0) ||
      isShoppingCartDeactivated
    ) {
      NotificationManager.error({
        message: 'notification.error.proceedCheckout',
        description: 'notification.error.cartListIsEmpty',
      });

      history.replace(linkGenerator('/cart'));
    }
    if ((listCart?.items || []).some((item) => !!item?.error)) {
      NotificationManager.error({
        message: 'notification.error.proceedCheckout',
        description: 'notification.error.nonexistentNumberInCart',
      });

      history.replace(linkGenerator('/cart'));
    }
  }, [listCart, isShoppingCartDeactivated, fetchCartState, history]);

  useEffect(() => {
    setPageInfoData({
      pageName: `checkout step ${currentTab}`,
      pageId,
      pageType: PAGE_TYPES.CHECKOUT,
    });
  }, [currentTab, pageId, setPageInfoData]);

  useEffect(() => {
    if (
      fetchCartState === ASYNC_STATUS.SUCCESS &&
      isValidArray(listCart?.items)
    ) {
      dispatch(
        trackCart({
          [TRACK_DATA.CART]: listCart?.items,
        })
      );
    }
  }, [dispatch, fetchCartState, listCart]);

  const handleValidateFields = (tabIndex) => {
    let validateFields = [];

    if (currentTab.toString() === '3') {
      validateFields = [...step3ValidationFields];
    } else if (tabIndex === '2') {
      validateFields = [...step1ValidateFields];
    } else if (tabIndex === '3') {
      validateFields = [...step1ValidateFields, ...step2ValidationFields];
    }

    return validateFields;
  };

  const changeTabHandler = async (tabIndex) => {
    const tabName = tabIndex.toString();
    const validateFields = handleValidateFields(tabName);
    try {
      await form.validateFields(validateFields);
      window.scrollTo({ top: 0 });

      setCurrentTab(+tabIndex);
    } catch (errorInfo) {
      notificationComponent({
        type: NOTIFICATION_TYPE.ERROR,
        message: (errorInfo?.errorFields || []).map((err) => (
          <span style={{ display: 'block' }} key={err?.errors}>
            {err?.errors}
          </span>
        )),
      });
    }
  };

  const handleSubmit = (value) => {
    if (!value) return;
    const deliveryAddr = getFormDeliveryAddr(value);

    if (deliveryAddr.region && regions[deliveryAddr.region]) {
      deliveryAddr.regionCode = deliveryAddr.region;
      deliveryAddr.region = regions[deliveryAddr.region].label;
    }

    const submitValue = {
      referenceNumber: value.referenceNumber,
      deliveryAddressPartnerNumber:
        value.currentDeliveryAddr === 1
          ? value.myAddrPartnerNumber
          : value.newAddrPartnerNumber,
      deliveryAddress: deliveryAddr,
      shippingNotes: value.shippingNotes,
      ...(value?.serviceCode && { serviceCode: value?.serviceCode }),
    };

    dispatch(ordersActions.placeAnOrder(submitValue));
  };

  const initialValues = useMemo(() => {
    const address = isValidArray(deliveryAddresses)
      ? deliveryAddresses[0]
      : customerAddress;
    const names = getAddress(address).name;

    return {
      myAddrName: names,
      myAddrName2: '',
      myAddrCity: address?.city,
      myAddrCountry: address.country,
      myAddrHouseNumber: address?.houseNumber,
      myAddrStreet: address?.street,
      myAddrStreet4: address?.street4,
      myAddrRegion: address?.region,
      myAddrZip: address?.zip,
      myAddrPhoneNumber: address?.phoneNumber,
      myAddrEmail: address?.eMail,
      myAddrPartnerNumber: address?.partnerNumber,
      currentDeliveryAddr: 1,
    };
  }, [deliveryAddresses, customerAddress]);

  if (isCustomerLoading) {
    return <B2becSpinner isLoading />;
  }

  const items = [
    {
      key: '1',
      label: (
        <B2becStepItemLabel
          label={t('checkout.tab.addressInformation')}
          index={1}
        />
      ),
      children: (
        <StepOneComponent
          form={form}
          tab={currentTab}
          setTabHandler={changeTabHandler}
        />
      ),
    },
    {
      key: '2',
      label: (
        <B2becStepItemLabel
          label={t('checkout.tab.paymentAndShipping')}
          index={2}
        />
      ),
      children: (
        <StepTwoComponent
          form={form}
          tab={currentTab}
          setTabHandler={changeTabHandler}
        />
      ),
      forceRender: true,
    },
    {
      key: '3',
      label: (
        <B2becStepItemLabel
          label={t('checkout.tab.reviewAndOrder')}
          index={3}
        />
      ),
      children: (
        <StepThreeComponent
          form={form}
          tab={currentTab}
          setTabHandler={changeTabHandler}
          submitFormHandler={() => form.submit()}
        />
      ),
    },
  ];

  return (
    <CheckoutProvider>
      <B2becSpinner isLoading={isCheckoutLoading} />
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        initialValues={initialValues}
        validateMessages={{
          string: {
            max: t('errors.maxInputExceeded'),
          },
        }}
      >
        <div className="checkout">
          <B2becInfoLayout>
            <B2becInfoLayout.Title>
              <h1>{t('checkout.headline')}</h1>
            </B2becInfoLayout.Title>
            <B2becInfoLayout.Content>
              <B2becSteps
                defaultActiveKey="1"
                activeKey={`${currentTab}`}
                onChange={(key) => changeTabHandler(key)}
                items={items}
              />
            </B2becInfoLayout.Content>
          </B2becInfoLayout>
        </div>
      </Form>
    </CheckoutProvider>
  );
}

CheckoutPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default CheckoutPage;
