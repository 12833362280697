import { Col, Form, Row } from 'antd';
import { B2bTechnicalUserForm } from 'components/B2bTechnicalUser';
import { NotificationManager } from 'components/Notification';
import PunchoutInfoModal from 'components/PunchoutInfoModal';
import useAsync from 'hooks/useAsync';
import { USER_FORM_TYPES } from 'libs/constants';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';
import { TECHNICAL_USER, USER_STATUS_ARRAY } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminFapiTechnicalUser } from 'services/adminFapi';
import { selectUserCountryId } from 'store/selectors/userSelector';

const { useForm } = Form;

const AddTechnicalUserForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();

  const [responseData, setResponseData] = useState(null);
  const [shouldDisplayModal, setDisplayModal] = useState(false);

  const countryId = useSelector(selectUserCountryId);

  const handleBackToUserList = () => {
    history.push(linkGenerator(`/user-management/${TECHNICAL_USER}`));
  };

  const onSuccess = useCallback((response) => {
    if (response?.data) {
      const { punchOutType, username, password, ...rest } = response?.data;
      if (punchOutType === PUNCHOUT_TYPES.OCI) {
        const base64Secrets = window.btoa(
          `accessKey=${username}&secret=${password}`
        );

        const base64Url = `${window.location.origin}/punchout/oci/${base64Secrets[0]}${base64Secrets}`;

        setResponseData({ punchOutType, url: base64Url });
      } else {
        setResponseData({ punchOutType, username, password, ...rest });
      }
      setDisplayModal(true);
    }
  }, []);

  const onError = useCallback(
    (error) => {
      let errorMessage = null;
      try {
        const err = error?.response?.data;
        if (err.includes('460')) {
          errorMessage = t('form.validate.emailInUse');
          form.setFields([
            {
              name: 'email',
              errors: [t('form.validate.emailInUse')],
            },
          ]);
        }
        if (err.includes('470')) {
          errorMessage = t('notification.error.technicalUsernameIsExisted');
        }
      } catch {
        // TODO
      }
      NotificationManager.error({
        message: 'notification.error.addTechnicalUser',
        description: errorMessage,
      });
    },
    [form, t]
  );

  const { execute: executeCreateNewTechnicalUser } = useAsync(
    adminFapiTechnicalUser.createNewTechnicalUser,
    false,
    onSuccess,
    onError
  );

  const initialValues = {
    status: USER_STATUS_ARRAY[1].value,
    countryId,
    punchOutType: PUNCHOUT_TYPES.OCI,
  };

  return (
    <>
      <div className="invite-new-user">
        <span className="note">{t('form.note.mandatory')}</span>
        <div className="invite-new-user__information">
          <h2>{t('userManagement.userInformation.title')}</h2>
          <Row>
            <Col span={24} xl={12}>
              <B2bTechnicalUserForm
                type={USER_FORM_TYPES.CREATE}
                onFinish={executeCreateNewTechnicalUser}
                initialValues={initialValues}
                onCancel={handleBackToUserList}
                form={form}
              />
            </Col>
          </Row>
        </div>
      </div>
      <PunchoutInfoModal
        isVisible={shouldDisplayModal}
        content={responseData}
        afterClose={handleBackToUserList}
        onOk={() => setDisplayModal(false)}
      />
    </>
  );
};

export default AddTechnicalUserForm;
