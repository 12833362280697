import { ADDRESS_SCHEME_TYPES } from 'libs/constants/configs';
import { getAddress } from 'libs/utils/addressUtils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAddressScheme,
  selectRegions,
} from 'store/selectors/configSelector';

const useRegion = () => {
  const regions = useSelector(selectRegions);
  const addressScheme = useSelector(selectAddressScheme);
  const isUSAAddressScheme = addressScheme === ADDRESS_SCHEME_TYPES.USA;
  const formattedRegions = useMemo(() => {
    return Object.values(regions).map((region) => ({
      value: region.id,
      label: region.label,
    }));
  }, [regions]);
  const isShowRegion = formattedRegions.length > 0;

  const getAddressWithRegion = useCallback(
    (data, ignores, separator) =>
      getAddress(data, regions, ignores, separator, isUSAAddressScheme),
    [regions, isUSAAddressScheme]
  );

  return {
    regions: formattedRegions,
    isShowRegion,
    getAddressWithRegion,
  };
};

export default useRegion;
