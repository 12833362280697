import { Col, Form, Input, Radio, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import AddressSelect from 'components/DeliveryAddressBlock';
import useRegion from 'hooks/useRegion';
import { ADDRESS_SCHEME_TYPES } from 'libs/constants/configs';
import { validateZipCode } from 'libs/utils/validatorUtils';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAddressScheme,
  selectCountryNames,
  selectShouldHideHouseNumberEntryField,
} from 'store/selectors/configSelector';
import { getDeliveryAddresses } from 'store/selectors/customerSelector';

const CustomAddressSelect = (props) => {
  const { id, onChange } = props;
  const [t] = useTranslation();

  const [isNewAddress, setIsNewAddress] = React.useState(false);
  const [forceRender, setForceRender] = React.useState();

  const rawDeliveryAddresses = useSelector(getDeliveryAddresses);
  const countryNamesConfig = useSelector(selectCountryNames);
  const shouldHideHouseNumber = useSelector(
    selectShouldHideHouseNumberEntryField
  );
  const addressScheme = useSelector(selectAddressScheme);
  const isUSAAddressScheme = addressScheme === ADDRESS_SCHEME_TYPES.USA;

  const { isShowRegion, regions } = useRegion();

  const addresses = useMemo(() => {
    return rawDeliveryAddresses.map(({ region, ...addr }) => {
      const foundCountryName = countryNamesConfig.find(
        (config) => config.value === addr.country
      );

      if (isShowRegion) {
        return {
          ...addr,
          country: foundCountryName ? foundCountryName.value : addr.country,
          region,
        };
      }
      return addr;
    });
  }, [rawDeliveryAddresses, countryNamesConfig, isShowRegion]);

  const handleSelectAddress = useCallback(
    (value) => {
      onChange(value);
      setIsNewAddress(false);
    },
    [onChange]
  );

  const handleToggleNewAddress = useCallback(() => {
    let defaultCountry = '';
    if (addresses && addresses.length > 0) {
      defaultCountry = addresses[0].country;
    }

    const foundCountryName = countryNamesConfig.find(
      (config) => config.value === defaultCountry
    );

    onChange({
      country: foundCountryName ? foundCountryName.name : defaultCountry,
    });
    setForceRender(Math.random());
    setIsNewAddress(true);
  }, [addresses, countryNamesConfig, onChange]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <AddressSelect
          key={forceRender}
          placeholder={t('rental.modal.locationMachine')}
          deliveryAddresses={addresses}
          onChange={handleSelectAddress}
        />
      </Col>
      <Col span={24}>
        <Radio onClick={handleToggleNewAddress} checked={isNewAddress}>
          {t('form.label.newAddress')}
        </Radio>
      </Col>
      {isNewAddress && (
        <>
          <Col flex="auto">
            <Form.Item
              name={[id, 'street']}
              label={t('form.label.street')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {!shouldHideHouseNumber && (
            <Col span={12}>
              <Form.Item
                name={[id, 'houseNumber']}
                label={t('form.label.houseNumber')}
                className="custom-form-item"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name={[id, 'street4']}
              label={t('form.label.street4')}
              className="custom-form-item"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} lg={{ span: 8 }} order={isUSAAddressScheme ? 3 : 1}>
            <Form.Item
              name={[id, 'zip']}
              label={t('form.label.zip')}
              className="custom-form-item"
              rules={[
                { required: true },
                ({ getFieldValue }) => {
                  const country = getFieldValue([id, 'country']);
                  return validateZipCode(country);
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {isShowRegion && (
            <Col span={24} lg={{ span: 8 }} order={2}>
              <Form.Item
                name={[id, 'region']}
                label={t('form.label.region')}
                className="custom-form-item"
                rules={[{ required: true }]}
              >
                <B2becCustomSelect
                  placeholder={t('form.placeHolder.region')}
                  options={regions}
                />
              </Form.Item>
            </Col>
          )}
          <Col
            flex="auto"
            lg={{ span: isShowRegion ? 8 : 'auto' }}
            order={isUSAAddressScheme ? 1 : 3}
          >
            <Form.Item
              name={[id, 'city']}
              label={t('form.label.city')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[id, 'country']}
              label={t('form.label.country')}
              className="custom-form-item"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CustomAddressSelect;
